<template>
    <div class="language-choice-container">
        <button 
            :class="language.selected ? null : 'language-choice-deselected'"
            class="language-choice">
            <font>
                {{ language.code.toUpperCase() }}
            </font>
        </button>
    </div>
</template>

<script>

export default {
  name: "LanguageButton",
  props: {
    // Object that represents a language and the 
    // selection status, e.g. 
    //
    // {
    //      language: "NL",
    //      selected: true
    // }
    language: {
        type: Object,
        required: true,
    }
  }
};

</script>

<style scoped>

.language-choice-container {
    display: inline;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.language-choice {
    border: none;
    background-color: black;
    width: 100px;
    height: 100px;
    border-radius: 60px;
    color: white;
    border: 10px solid white;
    margin: 5px;
    font-family: "Colfax-Black", Helvetica, Arial, serif;
    font-size: 32px;
}

.language-choice-deselected {
    background-color: #999999;
}

.language-choice-container button {
    padding: 0;
}

</style>