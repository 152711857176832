<template>
  <div class="start-layout">
    <div class="video-wrapper">
    <div class="title-wrapper">
          <div class="title-container">
      <div class="titlelarge">
        <div>{{ content.title }}</div>
      </div>

      <div class="title">
        <div id="metjohnquote">{{ getEmsDataPropertyText('subtitle') }}</div>
      </div>
    </div>
    </div>

    <StartVideoPlayer>
      <video class="start" :src="getEmsDataPropertyText('video_page1')" autoplay muted loop />
      <router-link :to="{name: 'intro'}">
        <RoundStartButton class="start-page-rounded-button" />
      </router-link>
    </StartVideoPlayer>
    </div>

  <div class="bottom-wrapper">
    <LanguageChoiceContainer class="languages-container" />
    <router-link :to="{name: 'intro'}">
      <o-button type="primary" class="o-btn--primary" :label="content.button_start_vlog_label" ></o-button>
    </router-link>
  </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CONST from "@/utils/Constants";
import ApiHelper from '@/helpers/ApiHelper';
// @ is an alias to /src
import StartVideoPlayer from "@/components/StartVideoPlayer.vue";
import RoundStartButton from "@/components/RoundStartButton.vue";
import LanguageChoiceContainer from "@/components/LanguageChoiceContainer.vue";
import oButton from '@/objects/o-button/o-button.vue';

export default {
  name: "start",
  components: {
    StartVideoPlayer,
    RoundStartButton,
    'o-button': oButton,
    LanguageChoiceContainer
  },
  computed: {
    ...mapState(['lang']),
    content() {
      return this.$store.getters.content;
    }
  },
  methods: {
    fetchData(lang) {
      ApiHelper
            .get(
              CONST.CMS.ROUTE.CONTENT + lang, { server: 'blockrocker_cms' }
            )
            .then((res) => {
              this.$store.commit('updateContent', { data: res.data.data, lang: lang })
            })
    }
  },

  mounted() {
    this.fetchData('nl');
    this.fetchData('de');
    this.fetchData('en');
  }
};
</script>

<style lang="scss">
.start-page-rounded-button {
  bottom: 34% !important;
}
</style>

<style scoped>

.title-wrapper {
  position: absolute;
  top: -110px;
  width: 100%;
}

.bottom-wrapper {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.title-container {
  width: fit-content;
  height: fit-content;
  margin: 0 auto;
  overflow: hidden;
}

.titlelarge {
  background-color: black;
  padding: 25px;
  font-family: "Colfax-Black", Helvetica, Arial, serif;
  font-size: 56px;
  color: white;
}

.title {
  background-color: rgba(255, 255, 255, 0);
  top: 240px;
  height: 54px;
  width: 225px;
  margin: 0;
  left: 623px;
  color: white;
  font-size: 32px;
  background-color: black;
  float: right;
  overflow: hidden;
}

#metjohnquote {
  font-family: "MarkerFelt-Thin";
}

.language-choice {
  display: inline-block;
}

.languages-container {
  margin-top: 8px;
}

.start {
  background-color: white;
}
</style>