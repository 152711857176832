<template>
    <div class="columns">
        <div class="column is-3"></div>
        <div class="column is-6">
            <LanguageButton
                v-for="lang in languages"
                v-bind:key="lang.code"
                :language="lang"
                @click.native="changeLanguage(lang.code)" />
        </div>
        <div class="column is-3"></div>
    </div>
</template>

<script>

import LanguageButton from "@/components/LanguageButton.vue";

export default {
    name: "LanguageChoiceContainer",
    components: {
        LanguageButton
    },
    data: function () {
        return {
            currentLanguageCode: "nl",
            languages: [
                {
                    code: "nl",
                    selected: true
                },
                {
                    code: "de",
                    selected: false
                },
                {
                    code: "en",
                    selected: false
                }
            ]  
        }
    },
    mounted: function () {
        this.changeLanguage(this.$store.getters.GET_LANG);
    },
    methods: {
        changeLanguage: function (languageCode) {
            this.languages.forEach(lang => {
                // setting selected language to active and everything else to inactive
                lang.selected = lang.code == languageCode;
            });
            
            this.currentLanguageCode = languageCode;
            this.$store.commit('SET_LANG', languageCode);
        }
    },
};

</script>

<style scoped>

</style>